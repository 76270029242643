import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";
import { useFormFields } from "../../lib/hooksLib";
const RegisterEvent = () => {
  const { id } = useParams();
  const nav = useNavigate();
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    name: "",
    email: "",
    elo: "",
  });

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      await register({ ...fields });
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
    setIsLoading(false);
    nav(`/events/${id}`);
    // handle form submission logic here
  }

  function register(particiant) {
    return API.post("events", `/register/${id}`, {
      body: particiant,
    });
  }

  return (
    <Container>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <h2 className="text-center">Event Registration</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="name">
              <Form.Label>Name:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                value={fields.name}
                onChange={handleFieldChange}
              />
            </Form.Group>

            <Form.Group controlId="email">
              <Form.Label>Email address:</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={fields.email}
                onChange={handleFieldChange}
              />
            </Form.Group>

            <Form.Group controlId="elo">
              <Form.Label>elo:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your elo"
                value={fields.elo}
                onChange={handleFieldChange}
              />
            </Form.Group>

            <LoaderButton
              variant="primary"
              type="submit"
              block={isLoading ? 1 : 0}
              isLoading={isLoading}
            >
              Register
            </LoaderButton>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default RegisterEvent;
