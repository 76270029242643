import React from "react";
import { Nav, Container, Row, Col, ListGroup } from "react-bootstrap";
import "./Footer.css";
const MY__ACCOUNT = [
  {
    display: "Author Profile",
    url: "/seller-profile",
  },
  {
    display: "Create Item",
    url: "/create",
  },
  {
    display: "Collection",
    url: "/market",
  },
  {
    display: "Edit Profile",
    url: "/edit-profile",
  },
];

const RESOURCES = [
  {
    display: "Help Center",
    url: "#",
  },
  {
    display: "Partner",
    url: "#",
  },
  {
    display: "Community",
    url: "#",
  },
  {
    display: "Activity",
    url: "#",
  },
];

const COMPANY = [
  {
    display: "About",
    url: "#",
  },
  {
    display: "Career",
    url: "#",
  },
  {
    display: "Ranking",
    url: "#",
  },
  {
    display: "Contact Us",
    url: "/contact",
  },
];

const Footer = () => {
  return (
    <footer className="flex-bottom bg-light  py-0">
      <Container>
        <Row>
          <Col lg={3} md={6} sm={6} className="mb-4">
            <div className="logo">
              <h2 className=" d-flex gap-2 align-items-center ">
                <span>
                  <i className="ri-fire-fill"></i>
                </span>
                Pingpongr
              </h2>
              <p>
                Pingpongr is a social network designed for ping pong
                enthusiasts. Find other players. Join events! With its
                user-friendly interface and interactive features, Pingpongr is
                the perfect platform for anyone looking to enhance their
                experience with this exciting sport.
              </p>
            </div>
          </Col>

          <Col lg={2} md={3} sm={6} className="mb-4">
            <h5>My Account</h5>
            <ListGroup className="list-group">
              {MY__ACCOUNT.map((item, index) => (
                <ListGroup.Item key={index} className="list-item">
                  <Nav.Link to={item.url}> {item.display} </Nav.Link>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>

          <Col lg={2} md={3} sm={6} className="mb-4">
            <h5>Resources</h5>
            <ListGroup className="list-group">
              {RESOURCES.map((item, index) => (
                <ListGroup.Item key={index} className="list-item">
                  <Nav.Link to={item.url}> {item.display} </Nav.Link>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>

          <Col lg={2} md={3} sm={6} className="mb-4">
            <h5>Company</h5>
            <ListGroup className="list-group">
              {COMPANY.map((item, index) => (
                <ListGroup.Item key={index} className="list-item">
                  <Nav.Link to={item.url}> {item.display} </Nav.Link>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          {/* 
          <Col lg={3} md={6} sm={6} className="mb-4">
            <h5>Newsletter</h5>
            <input type="text" className="newsletter" placeholder="Email" />
            <button className="btn btn-primary">Subscribe</button>

            <div className="social-Nav.Links d-flex gap-3 align-items-center ">
              <span>
                <Nav.Link to="#">
                  <i className="ri-facebook-line">hi</i>
                </Nav.Link>
              </span>
              <span>
                <Nav.Link to="#">
                  <i className="ri-instagram-line">hi</i>
                </Nav.Link>
              </span>
              <span>
                <Nav.Link to="#">
                  <i className="ri-twitter-line">hi</i>
                </Nav.Link>
              </span>
              <span>
                <Nav.Link to="#">
                  <i className="ri-telegram-line">hi</i>
                </Nav.Link>
              </span>
              <span>
                <Nav.Link to="#">
                  <i className="ri-discord-line">hi</i>
                </Nav.Link>
              </span>
            </div>
          </Col> */}

          <Col lg={12} className=" mt-4 text-center">
            <p className="copyright">
              {" "}
              Copyrights 2023, Developed by Alvin. @pingpongr. All Rights
              Reserved.{" "}
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
