import React, { useState, useLayoutEffect } from "react";
import { useAppContext } from "../../lib/contextLib";

import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import styles from "./Club.css";
import { onError } from "../../lib/errorLib";
import { API } from "aws-amplify";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import Login from "../Login/Login";
import { render } from "react-dom";

export default function Club() {
  const { id } = useParams();
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [clubName, setClubName] = useState("");
  const [clubId, setClubId] = useState("");
  const [clubNameUncleaned, setClubNameUncleaned] = useState("");
  const [clubOwner, setClubOwner] = useState("");
  const [clubAdmins, setClubAdmins] = useState([]);
  const [events, setEvents] = useState([]);
  const [members, setMembers] = useState([]);
  const [clubPicture, setClubPicture] = useState("");
  const [clubDescription, setClubDescription] = useState("");
  const [clubLocation, setClubLocation] = useState("");
  const [clubEvents, setClubEvents] = useState([]);
  const { isAuthenticated } = useAppContext();

  useLayoutEffect(() => {
    async function onLoad() {
      setIsLoading(true);
      if (!isAuthenticated) {
        return;
      }
      try {
        const club = await loadClub();
        const response = await loadClubEvents(); // Use an empty array as a default value
        for (const prop in response) {
          console.log(response[prop]);
        }
        setClubEvents(response);
        setClubId(club.clubId);
        setClubName(club.clubName);
        setClubNameUncleaned(club.clubNameUncleaned);
        setClubOwner(club.clubOwner);
        setClubAdmins(club.clubAdmins);
        setEvents(club.events);
        setMembers(club.members);
        setClubPicture(club.clubPicture);
        setClubDescription(club.clubDescription);
        setClubLocation(club.clubLocation);
      } catch (e) {
        onError(e);
      }
      setIsLoading(false);
    }
    if (isAuthenticated) {
      onLoad();
    }
  }, [isAuthenticated]);

  function loadClub() {
    return API.get("clubs", `/clubs/${id}`);
  }

  function loadClubEvents() {
    return API.get("events", `/listclubevents/${id}`);
  }

  function createEvent() {
    nav(`/clubs/${clubName}/events/new`);
  }

  function registerEvent(id) {
    nav(`/events/${clubName}/${id}/register`);
  }

  function renderClub() {
    return (
      <div className={styles.club}>
        {isLoading ? (
          <LoaderButton>
            <span className="spinner-border spinner-border-sm"></span> Loading…
          </LoaderButton>
        ) : (
          <Container>
            <Row>
              <Col md={8}>
                <Card className={`mb-3 ${styles.card}`}>
                  <Image src={clubPicture} fluid />
                  <Card.Body>
                    <Card.Title className={styles["card-title"]}>
                      {clubNameUncleaned}
                    </Card.Title>
                    <Card.Text className={styles["card-text"]}>
                      {clubDescription}
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer className={styles["card-footer"]}>
                    <small className="text-muted">{clubLocation}</small>
                  </Card.Footer>
                </Card>

                <Card className={`mb-3 ${styles.card}`}>
                  <Card.Body>
                    <Card.Title className={styles["card-title"]}>
                      Events
                    </Card.Title>
                    <Card.Text className={styles["card-text"]}></Card.Text>
                    {clubEvents.length === 0 ? (
                      <>
                        <p className={styles["card-text"]}>No events yet.</p>
                        <button onClick={createEvent}> Create new Event</button>
                      </>
                    ) : (
                      Object.keys(clubEvents).map((event) => {
                        return (
                          <div
                            key={clubEvents[event].eventId}
                            className="event-containe"
                          >
                            <div className="event-details">
                              <div className="event-header">
                                <a
                                  className="link"
                                  href={`/events/${clubEvents[event].eventId}`}
                                >
                                  <h4>
                                    Event Name: {clubEvents[event].eventName}
                                  </h4>
                                </a>
                              </div>
                              <Card className="event-card-item">
                                <h5>Date {clubEvents[event].date}</h5>
                              </Card>
                              <Card className="event-card-item">
                                Time:
                                {clubEvents[event].time}
                              </Card>
                              {clubEvents[event].attendees === undefined ? (
                                <Card className="event-card-item">
                                  No participants yet.
                                </Card>
                              ) : (
                                <Card className="event-card-item">
                                  Participants:{" "}
                                  {
                                    Object.keys(clubEvents[event].attendees)
                                      .length
                                  }
                                </Card>
                              )}
                              <button
                                className="event-button-item"
                                onClick={() =>
                                  registerEvent(clubEvents[event].eventId)
                                }
                              >
                                Register
                              </button>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </Card.Body>

                  <div className="p-3">
                    <hr></hr>
                    <button onClick={createEvent}> Create new event</button>
                  </div>
                </Card>

                <Card className={`mb-3 ${styles.card}`}>
                  <Card.Body>
                    <Card.Title className={styles["card-title"]}>
                      Members
                    </Card.Title>
                    <ul>
                      {members === undefined ? (
                        <p className={styles["card-text"]}>No members yet.</p>
                      ) : (
                        members.map((member) => (
                          <li
                            key={member.membersId}
                            className={styles["card-text"]}
                          >
                            {member.membersName} ({member.membersElo})
                          </li>
                        ))
                      )}
                    </ul>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={4}>
                <Card className={`mb-3 ${styles.card}`}>
                  <Card.Body>
                    <Card.Title className={styles["card-title"]}>
                      About the Club
                    </Card.Title>
                    <p className={styles["card-text"]}>
                      Club Owner: {clubOwner}
                    </p>
                    <p className={styles["card-text"]}>
                      {/* Club Admins: {clubAdmins.join(", ")} */}
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    );
  }

  function renderLander() {
    return (
      <Container>
        <Login></Login>
      </Container>
    );
  }
  function renderall() {
    return (
      <Container className="ListClubs">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">{clubNameUncleaned}</h2>
        {isLoading && <LoaderButton isLoading={isLoading}></LoaderButton>}
        {!isLoading && renderClub()}
      </Container>
    );
  }

  return <>{isAuthenticated ? renderall() : renderLander()}</>;
}
