import React from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import heroImg from "../../assets/images/hero.jpg";

const HeroSectionContainer = styled.section`
  margin-top: 120px;
  margin-bottom: 120px;
`;

const HeroContent = styled.div`
  h2 {
    line-height: 55px;
    font-size: 2.5rem;

    span {
      background: linear-gradient(-45deg, #e250e5, #4b50e6, #e250e5, #4b50e6);
      background-size: 100% 100%;
      background-clip: text;
      -webkit-text-stroke: 3px transparent;
      -webkit-text-fill-color: #14141f;
      margin-left: 7px !important;
      display: inline-block;
    }
  }

  p {
    font-size: 1.2rem;
    line-height: 30px;
    margin-bottom: 40px;
  }

  .hero__btns {
    button {
      border: none;
      outline: none;
      padding: 17px 30px !important;
      margin-right: 10px !important;
      border-radius: 50px !important;
      border: 1px solid #5142fc !important;
      font-size: 0.9rem;
      background: #fff;
      i {
        color: #5142fc;
        font-size: 1rem;
      }
      a {
        text-decoration: none;
        color: #5142fc;
      }
      &:hover {
        color: #fff;
        background: #5142fc;
        i {
          color: #fff;
        }
        a {
          color: #fff;
        }
      }
    }
  }
`;

const ExploreButton = styled.button`
  border: none;
  outline: none;
  padding: 17px 30px !important;
  margin-right: 10px !important;
  border-radius: 50px !important;
  border: 1px solid #5142fc !important;
  font-size: 0.9rem;
  background: #fff;
  i {
    color: #5142fc;
    font-size: 1rem;
  }
  a {
    text-decoration: none;
    color: #5142fc;
  }
  &:hover {
    color: #fff;
    background: #5142fc;
    i {
      color: #fff;
    }
    a {
      color: #fff;
    }
  }
`;

const HeroImage = styled.div`
  img {
    border-radius: 7px;
    width: 100%;
  }
}`;

const HeroSection = () => {
  return (
    <HeroSectionContainer>
      <Container>
        <Row>
          <Col lg="6" md="6">
            <HeroContent>
              <h2>
                Find <span>ping pong friends</span>!
              </h2>
              <p>
                Discover local ping pong enthusiasts and team up for a match
                with just a few clicks!
              </p>

              <div className="hero__btns d-flex align-items-center gap-4">
                <ExploreButton className="explore__btn d-flex align-items-center gap-2">
                  <i className="ri-rocket-line"></i>{" "}
                  <Nav.Link href="/signup">Join Now!</Nav.Link>
                </ExploreButton>
              </div>
            </HeroContent>
          </Col>

          <Col lg="6" md="6">
            <HeroImage>
              <img src={heroImg} alt="" />
            </HeroImage>
          </Col>
        </Row>
      </Container>
    </HeroSectionContainer>
  );
};

export default HeroSection;
