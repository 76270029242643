import React, { useRef, useState } from "react";
import { Form, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import { onError } from "../../lib/errorLib";
import config from "../../config";
import { API } from "aws-amplify";
import { s3Upload } from "../../lib/awsLib";

import { useAppContext } from "../../lib/contextLib";
import "./NewNote.css";

export default function NewNote() {
  const file = useRef(null);
  const nav = useNavigate();
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { isAuthenticated } = useAppContext();
  function validateForm() {
    return content.length > 0;
  }

  function handleFileChange(event) {
    file.current = event.target.files[0];
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }

    setIsLoading(true);

    try {
      const attachment = file.current ? await s3Upload(file.current) : null;

      await createNote({ content, attachment });
      nav("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function createNote(note) {
    return API.post("notes", "/notes", {
      body: note,
    });
  }

  function renderNewNote() {
    return (
      <Container>
        <div className="NewNote">
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="content">
              <Form.Control
                value={content}
                as="textarea"
                onChange={(e) => setContent(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mt-2" controlId="file">
              <Form.Label>Attachment</Form.Label>
              <Form.Control onChange={handleFileChange} type="file" />
            </Form.Group>
            <LoaderButton
              block={isLoading ? 1 : 0}
              type="submit"
              size="lg"
              variant="primary"
              isLoading={isLoading}
              disabled={!validateForm()}
            >
              Create
            </LoaderButton>
          </Form>
        </div>
      </Container>
    );
  }

  function renderLander() {
    return (
      <Container>
        <p1> Login to see your notes</p1>
      </Container>
    );
  }

  return (
    <Container>{isAuthenticated ? renderNewNote() : renderLander()}</Container>
  );
}
