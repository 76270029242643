import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { useAppContext } from "../../lib/contextLib";
import { API } from "aws-amplify";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import { useFormFields } from "../../lib/hooksLib";
import { onError } from "../../lib/errorLib";
import Login from "../Login/Login";
import "./NewClub.css";
const CreateClubPage = () => {
  const { isAuthenticated } = useAppContext();
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    clubName: "",
    clubNameUncleaned: "",
    clubPicture: "",
    clubDescription: "",
    clubLocation: "",
  });

  const [validationError, setValidationError] = useState("");

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    const cleanClubName = formatFilename(fields.clubNameUncleaned);
    // console.log(cleanClubName);

    try {
      fields.clubName = cleanClubName;
      await createClub({ ...fields });
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
    setIsLoading(false);
    nav(`/clubs/${fields.clubName}`);
  }

  function validateText(text) {
    // Regular expression that checks if the text contains only letters, numbers, and spaces
    const regex = new RegExp(/^[a-z0-9\s]+$/i);
    return regex.test(text);
  }
  function formatFilename(str) {
    // Replace spaces with an arbitrary character
    let formatted = str.replace(/\s+/g, "_");

    // Replace all special characters that are not hyphens with nothing
    formatted = formatted.replace(/[^\w\s-]/gi, "");

    // Replace all underscores with hyphens
    formatted = formatted.replace(/_/g, "-");

    //lowercase function
    formatted = formatted.toLowerCase();
    return formatted;
  }

  async function createClub(event) {
    return API.post("clubs", "/createclub", {
      body: event,
    });
  }
  function renderForm() {
    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="clubNameUncleaned">
          <Form.Label>Club Name</Form.Label>
          <Form.Control
            type="text"
            value={fields.clubNameUncleaned} //{formatFilename(fields.clubName)}
            onChange={handleFieldChange}
            placeholder="Berkeley Table Tennis Gym"
          />
          <p style={{ fontSize: "0.8em", marginTop: "0.5em" }}>
            No special characters
          </p>
        </Form.Group>

        <Form.Group controlId="clubPicture">
          <Form.Label>Club Picture URL</Form.Label>
          <Form.Control
            type="url"
            value={fields.clubPicture}
            onChange={handleFieldChange}
            placeholder="Enter club picture URL"
          />
        </Form.Group>

        <Form.Group controlId="clubDescription">
          <Form.Label>Club Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={fields.clubDescription}
            onChange={handleFieldChange}
            placeholder="Enter club description"
          />
        </Form.Group>

        <Form.Group controlId="clubLocation">
          <Form.Label>Club Location</Form.Label>
          <Form.Control
            type="text"
            value={fields.clubLocation}
            onChange={handleFieldChange}
            placeholder="1234 Main St, City, State Zip"
          />
        </Form.Group>

        <LoaderButton
          block={isLoading ? 1 : 0}
          isLoading={isLoading}
          variant="primary"
          type="submit"
          disabled={!validateText(fields.clubNameUncleaned)}
        >
          Create Club
        </LoaderButton>
      </Form>
    );
  }

  function renderLander() {
    return (
      <Container>
        <Login></Login>
      </Container>
    );
  }
  function renderall() {
    return (
      <Container className="ListClubs">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Create a Club</h2>
        {isLoading && <LoaderButton isLoading={isLoading}></LoaderButton>}
        {!isLoading && renderForm()}
      </Container>
    );
  }

  return <>{isAuthenticated ? renderall() : renderLander()}</>;
};

export default CreateClubPage;
