import React, { useState, useEffect } from "react";
import { ListGroup, Row, Col, Card, Button, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import "./ListClubs.css";
import MapLink from "../../lib/mapLink";
import { API } from "aws-amplify";
import { onError } from "../../lib/errorLib";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import { useAppContext } from "../../lib/contextLib";
import Login from "../Login/Login";
function ListClubs() {
  const [clubs, setClubs] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
      try {
        const clubs = await loadClubs();
        setClubs(clubs);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }
    if (isAuthenticated) {
      onLoad();
    }
  }, [isAuthenticated]);

  function loadClubs() {
    return API.get("clubs", "/listclubs");
  }

  function renderClubsList(clubs) {
    return (
      <Container>
        <Row>
          {clubs.map((club) => (
            <Col sm={12} md={12} lg={4} xl={3} key={club.clubId}>
              <div className="card-container">
                <Card>
                  <Card.Img
                    variant="top"
                    src={club.clubPicture}
                    className="club-picture"
                  />
                  <Card.Body>
                    <Card.Title>{club.clubName}</Card.Title>
                    <Card.Text>{club.clubDescription}</Card.Text>
                    <MapLink address={club.clubAddress} />
                    <Button
                      as={Link}
                      to={`/clubs/${club.clubName}`}
                      variant="primary"
                    >
                      Go to Club
                    </Button>
                  </Card.Body>
                  <Card.Footer>
                    <small className="text-muted">{club.clubLocation}</small>
                  </Card.Footer>
                </Card>
              </div>
            </Col>
          ))}
        </Row>
        <LoaderButton href="/clubs/new">Create New Club</LoaderButton>
      </Container>
    );
  }

  function renderLander() {
    return (
      <Container>
        <Login></Login>
      </Container>
    );
  }

  function renderClubs() {
    return (
      <Container className="ListClubs">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Clubs</h2>
        {isLoading && <LoaderButton isLoading={isLoading}></LoaderButton>}
        {!isLoading && renderClubsList(clubs)}
      </Container>
    );
  }

  return <>{isAuthenticated ? renderClubs() : renderLander()}</>;
}

export default ListClubs;
