import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { API } from "aws-amplify";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import { useFormFields } from "../../lib/hooksLib";
import { onError } from "../../lib/errorLib";
import "./NewEvent.css";
const NewEvent = () => {
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    content: "",
    attachment: "",
    club: "",
    date: "",
    time: "",
    location: "",
    description: "",
  });

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      await createEvent({ ...fields });
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
    //
    console.log({ ...fields }); // replace with actual code to handle form submission
    setIsLoading(false);
    // nav("/events");
  }

  async function createEvent(event) {
    return API.post("events", "/events", {
      body: event,
    });
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="content">
        <Form.Label>Content</Form.Label>
        <Form.Control
          type="text"
          name="content"
          value={fields.content}
          onChange={handleFieldChange}
          required
        />
      </Form.Group>
      <Form.Group controlId="attachment">
        <Form.Label>Attachment</Form.Label>
        <Form.Control
          type="text"
          name="attachment"
          value={fields.attachment}
          onChange={handleFieldChange}
          required
        />
      </Form.Group>
      <Form.Group controlId="club">
        <Form.Label>Club</Form.Label>
        <Form.Control
          type="text"
          name="club"
          value={fields.club}
          onChange={handleFieldChange}
          required
        />
      </Form.Group>
      <Form.Group controlId="date">
        <Form.Label>Date</Form.Label>
        <Form.Control
          type="date"
          name="date"
          value={fields.date}
          onChange={handleFieldChange}
          required
        />
      </Form.Group>
      <Form.Group controlId="time">
        <Form.Label>Time</Form.Label>
        <Form.Control
          type="time"
          name="time"
          value={fields.time}
          onChange={handleFieldChange}
          required
        />
      </Form.Group>
      <Form.Group controlId="location">
        <Form.Label>Location</Form.Label>
        <Form.Select
          name="location"
          value={fields.location}
          onChange={handleFieldChange}
          required
        >
          <option value="">Select a location...</option>
          <option value="Berkeley, CA">Berkeley, CA</option>
          <option value="Concord, CA">Concord, CA</option>
          <option value="Alameda, CA">Alameda, CA</option>
        </Form.Select>
      </Form.Group>
      <Form.Group controlId="description">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          name="description"
          value={fields.description}
          onChange={handleFieldChange}
          rows={3}
          required
        />
      </Form.Group>
      <LoaderButton
        block={isLoading ? 1 : 0}
        isLoading={isLoading}
        type="submit"
      >
        Submit
      </LoaderButton>
    </Form>
  );
};

export default NewEvent;
