import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Routes, Route } from "react-router-dom";
import { AppContext } from "./lib/contextLib";
import MyNavbar from "./components/Nav/Nav";
import { Container } from "react-bootstrap";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Members from "./pages/Members/Members";
import Login from "./pages/Login/Login";
import Footer from "./components/Footer/Footer";
import NotFound from "./pages/NotFound.js";
import Signup from "./pages/Login/Signup";
import ResetPassword from "./pages/Login/ResetPassword";
import Settings from "./pages/Settings/Settings";
import { RecoilRoot } from "recoil";
import BirthdayPartyPage from "./pages/birthday/supersecretpartypage";

import NewClub from "./pages/Clubs/NewClub";
import ListClubs from "./pages/Clubs/ListClubs";
import Club from "./pages/Clubs/Club";

import NewEvent from "./pages/Events/NewEvent";
import Events from "./pages/Events/ListEvents";
import EventPage from "./pages/Events/EventPage";
import RegisterEvent from "./pages/Events/RegisterEvent";
import NewClubEvent from "./pages/Events/NewClubEvent";

import NewNote from "./pages/Notes/NewNote";
import Notes from "./pages/Notes/Notes";
import ListNotes from "./pages/Notes/ListNotes";
import "./App.css";

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
      document.title = "Welcome!";
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }

    setIsAuthenticating(false);
  }

  return (
    <div className="d-flex flex-column" style={{ height: "100vh" }}>
      <RecoilRoot>
        <AppContext.Provider
          value={{
            isAuthenticated,
            isAuthenticating,
            userHasAuthenticated,
            setIsAuthenticating,
          }}
        >
          <MyNavbar></MyNavbar>
          <div className="flex-grow-1">
            <Container>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                {/* <Route
                  path="/secretpartypage"
                  element={<BirthdayPartyPage />}
                /> */}
                <Route path="/members" element={<Members />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/notes" element={<ListNotes />} />
                <Route path="/notes/new" element={<NewNote />} />
                <Route path="/notes/:id" element={<Notes />} />
                <Route path="/clubs" element={<ListClubs />} />
                <Route path="/login" element={<Login />} />
                <Route path="/clubs/new" element={<NewClub />} />
                <Route path="/clubs/:id" element={<Club />} />
                <Route
                  path="/clubs/:clubName/events/new"
                  element={<NewClubEvent />}
                />
                <Route path="/events/:id" element={<EventPage />} />
                <Route
                  path="/events/:club/:id/register"
                  element={<RegisterEvent />}
                />
                <Route path="/events" element={<Events />} />
                <Route path="/events/new" element={<NewEvent />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/login/reset" element={<ResetPassword />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="*" element={<NotFound />} />;
              </Routes>
            </Container>
          </div>
          <Footer></Footer>
        </AppContext.Provider>
      </RecoilRoot>
    </div>
  );
}

export default App;
