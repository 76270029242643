import React from "react";
import grillchill from "../../assets/images/grillchill.png";
import "./supersecretpartypage.css";

function BirthdayPartyPage() {
  return (
    <div>
      <h1>July 30 2023: Pickleball Tourney and BBQ</h1>
      <p>
        This page was made because we're not friends on facebook otherwise I
        would have sent you an invite there. I'm turning 35 and I'm having a
        party at my house. I'm going to be BBQing and we're going to play some
        pickleball.
      </p>
      <strong>pickleball</strong>
      <br></br>
      For the pickleball tournament I only have 6 paddles (2 of them aren't
      great) so please bring your own if you have one. I have a bunch of balls
      though. If you don't know how to play pickleball, it's like tennis but
      with a wiffle ball and a paddle. It's super fun and easy to learn. I'll
      have a quick tutorial before the tournament starts.
      <br></br>
      <br></br>
      <strong>Additional Guests</strong>
      <br></br>
      <p>
        If you want to bring a +1, please let me know so I can forecast how much
        food to prepare
      </p>
      <p>
        Please feel free to come over, say hello, and enjoy some delicious food.
        You can also swing by for a game of pingpong or particate in our
        pickleball tournament. And if you're up for it, you're welcome to stay
        and join us for the entire event!
      </p>
      <h1> Agenda </h1>
      <p> BBQ Drinks and Pickleball Tournament</p>
      <p>4:00pm Eat drink be merry</p>
      <p>5:00pm Pickleball Tournament (Courts are right next to our house)</p>
      <p></p>
      <p>~ 6:30 pm Eat drink be merry</p>
      <p>8:00 pm Hard stop at 8 cause we're old</p>
      <h1>menu</h1>
      <p>Brisket</p>
      <p>Pulled Pork</p>
      <p>Hoagies</p>
      <p>Mac and Cheese</p>
      <p>Salad</p>
      <div class="colorful">
        <a href="https://www.instagram.com/yo.alvin.cooks/?hl=en">
          SEE DEMO OF FOOD HERE
        </a>
        <a href="https://www.tiktok.com/@chefalvin?lang=en">AND HERE</a>
      </div>
      <br></br>
      <div className="address-container">
        <a href="https://goo.gl/maps/M5WxcJysaosoiC586"> Directions</a>
      </div>
      <img className="responsive-image" src={grillchill} alt="Birthday Party" />
    </div>
  );
}

export default BirthdayPartyPage;
