import { useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { useParams, useNavigate, Link } from "react-router-dom";
import { API } from "aws-amplify";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import { useFormFields } from "../../lib/hooksLib";
import { onError } from "../../lib/errorLib";
import { useAppContext } from "../../lib/contextLib";
import Login from "../Login/Login";
import "./NewEvent.css";
const NewClubEvent = () => {
  const { isAuthenticated } = useAppContext();
  const nav = useNavigate();
  const { clubName } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    eventName: "",
    attachment: "",
    date: "",
    time: "",
    location: "",
    description: "",
  });

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    fields.clubName = clubName;
    try {
      await createEvent({ ...fields });
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
    //
    // console.log({ ...fields }); // replace with actual code to handle form submission
    setIsLoading(false);
    nav(`/clubs/${clubName}`);
  }

  async function createEvent(event) {
    return API.post("events", `/createclubevent/${clubName}`, {
      body: event,
    });
  }

  function renderForm() {
    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="eventName">
          <Form.Label>Event Name</Form.Label>
          <Form.Control
            type="text"
            name="eventName"
            value={fields.content}
            onChange={handleFieldChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="location">
          <Form.Label>Location</Form.Label>
          <Form.Control
            type="text"
            name="location"
            value={fields.location}
            onChange={handleFieldChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="date">
          <Form.Label>Date</Form.Label>
          <Form.Control
            type="date"
            name="date"
            value={fields.date}
            onChange={handleFieldChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="time">
          <Form.Label>Time</Form.Label>
          <Form.Control
            type="time"
            name="time"
            value={fields.time}
            onChange={handleFieldChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="description">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            name="description"
            value={fields.description}
            onChange={handleFieldChange}
            rows={3}
            required
          />
        </Form.Group>
        <LoaderButton
          block={isLoading ? 1 : 0}
          isLoading={isLoading}
          type="submit"
        >
          Submit
        </LoaderButton>
      </Form>
    );
  }

  function renderLander() {
    return (
      <Container>
        <Login></Login>
      </Container>
    );
  }
  function renderall() {
    return (
      <Container className="ListClubs">
        {isLoading && <LoaderButton isLoading={isLoading}></LoaderButton>}
        {!isLoading && renderForm()}
      </Container>
    );
  }

  return <>{isAuthenticated ? renderall() : renderLander()}</>;
};

export default NewClubEvent;
