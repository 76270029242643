import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import "./ListEvents.css";
const Events = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    // Fetch the list of events from an API or database
    // For this example, we will just hardcode them
    const eventData = [
      {
        id: 1,
        name: "Example Event 1",
        date: "2021-09-01",
        location: "New York City",
      },
      {
        id: 2,
        name: "Example Event 2",
        date: "2021-10-01",
        location: "Los Angeles",
      },
      {
        id: 3,
        name: "Example Event 3",
        date: "2021-11-01",
        location: "Chicago",
      },
    ];

    setEvents(eventData);
  }, []);

  const handleRegistration = (eventId) => {
    // Handle registration logic here
    // console.log(`Registered for event with ID ${eventId}`);
  };

  return (
    <div>
      <h1>Upcoming Events</h1>
      <ul>
        {events.map((event) => (
          <li key={event.id}>
            <Link
              to={`/events/${event.id}`}
            >{`${event.name} - ${event.date} - ${event.location}`}</Link>
            <button onClick={() => handleRegistration(event.id)}>
              Register
            </button>
          </li>
        ))}
      </ul>
      <Button>
        <Link to="/events/new" className="white-link">
          Create New Event
        </Link>
      </Button>
    </div>
  );
};

export default Events;
