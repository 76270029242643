import React, { useState, useEffect } from "react";
import "./Members.css";
import { API } from "aws-amplify";
import { Container, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import { useAppContext } from "../../lib/contextLib";
import { onError } from "../../lib/errorLib";

export default function Members() {
  const [members, setMembers] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
      try {
        const members = await loadMembers();
        setMembers(members);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [isAuthenticated]);

  function loadMembers() {
    return API.get("users", "/listusers");
  }
  return (
    <Container>
      <div>
        <h1>Members</h1>
        <p> banans</p>
      </div>
    </Container>
  );
}
