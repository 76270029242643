const MapLink = ({ address }) => {
  const encodedAddress = encodeURIComponent(address);
  const mapUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;

  const handleClick = (event) => {
    event.preventDefault();
    window.open(mapUrl, "_blank");
  };

  return (
    <a
      href={mapUrl}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleClick}
    >
      {address}
    </a>
  );
};

export default MapLink;
