import React, { useState } from "react";
import { Auth } from "aws-amplify";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import { useAppContext } from "../../lib/contextLib";
import { useFormFields } from "../../lib/hooksLib";
import { useNavigate, Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { API } from "aws-amplify";
import { useRecoilState } from "recoil";
import { userState } from "../../atoms/atoms";

import "./Login.css";

export default function Login() {
  const { userHasAuthenticated, setEmailAddress } = useAppContext();
  const [, setUser] = useRecoilState(userState);
  const [isLoading, setIsLoading] = useState(false);
  const nav = useNavigate();
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
  });
  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }
  function getUser() {
    return API.get("users", `/user/${fields.email}`);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);
    try {
      const authed = await Auth.signIn(fields.email, fields.password);
      const user = await getUser();

      const _user = {
        ...user,
      };

      setUser({ ...user });
      userHasAuthenticated(true);
      nav("/");
    } catch (e) {
      alert(e.message);
      setIsLoading(false);
    }
  }

  return (
    <div className="Login">
      <Form onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Link to="/login/reset">Forgot password?</Link>
        <LoaderButton
          block={isLoading ? 1 : 0}
          size="lg"
          type="submit"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Login
        </LoaderButton>
      </Form>
    </div>
  );
}
