import React, { useState, useEffect } from "react";
import { Container, Card } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import LoaderButton from "../../components/LoaderButton/LoaderButton";
import { useAppContext } from "../../lib/contextLib";
import "./EventPage.css";
import { onError } from "../../lib/errorLib";
import { useFormFields } from "../../lib/hooksLib";
import Login from "../Login/Login";
import "./EventPage.css";
function EventPage() {
  const { id } = useParams();
  const nav = useNavigate();
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [event, setEvent] = useState();
  const [clubName, setClubName] = useState("");

  useEffect(() => {
    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }
      try {
        const event = await loadEvent(); // Use an empty array as a default value
        setClubName(event.clubName);
        console.log(event);
        setEvent(event);
        setIsLoading(false);
      } catch (e) {
        onError(e);
      }
      console.log(isLoading);
    }
    if (isAuthenticated) {
      onLoad();
    }
  }, [isAuthenticated]);

  function loadEvent() {
    return API.get("events", `/events/${id}`);
  }

  function registerEvent(id) {
    nav(`/events/${clubName}/${id}/register`);
  }

  function renderEventPage() {
    return (
      <div>
        {isLoading ? (
          <LoaderButton>
            <span className="spinner-border spinner-border-sm"></span> Loading…
          </LoaderButton>
        ) : (
          <Container>
            <h2 className="pb-3 mt-4 mb-3 border-bottom">{event.eventName}</h2>
            <Card key={event.date}>
              <Card.Body>
                <p>Date: {event.date}</p>
                <p>Time: {event.time}</p>
                <p>Location: {event.location}</p>
                <p>Description: {event.description}</p>

                <h5>Attendees:</h5>
                <ul
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    listStyle: "none",
                    padding: 0,
                  }}
                >
                  {event.attendees === undefined ? (
                    <p>No attendees yet</p>
                  ) : (
                    event.attendees
                      .sort((a, b) => b.participantsElo - a.participantsElo)
                      .map((attendee) => (
                        <li
                          key={attendee.participantId}
                          style={{ width: "100%", marginBottom: "10px" }}
                        >
                          <div
                            style={{
                              background: "#f1f1f1",
                              borderRadius: "5px",
                              padding: "10px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <p style={{ fontWeight: "bold", margin: 0 }}>
                                {attendee.participantsName}
                              </p>
                              <p style={{ fontSize: "14px", margin: 0 }}>
                                ID: {attendee.participantsId}
                              </p>
                            </div>
                            <p style={{ margin: 0 }}>
                              Elo: {attendee.participantsElo}
                            </p>
                          </div>
                        </li>
                      ))
                  )}
                </ul>
              </Card.Body>
            </Card>

            <button
              className="event-button-item"
              onClick={() => registerEvent(id)}
            >
              Register
            </button>
          </Container>
        )}
      </div>
    );
  }

  function renderLander() {
    return (
      <Container>
        <Login></Login>
      </Container>
    );
  }

  function renderall() {
    return (
      <Container className="ListClubs">
        {isLoading && <LoaderButton isLoading={isLoading}></LoaderButton>}
        {!isLoading && renderEventPage()}
      </Container>
    );
  }

  return <>{isAuthenticated ? renderall() : renderLander()}</>;
}
export default EventPage;
