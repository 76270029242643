import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useAppContext } from "../../lib/contextLib";
import "./Nav.css";
import { useRecoilState } from "recoil";
import { userState } from "../../atoms/atoms";
function MyNavbar() {
  const [user, _setUser] = useRecoilState(userState);
  const navigate = useNavigate();
  const { isAuthenticated, userHasAuthenticated, isAuthenticating } =
    useAppContext();

  async function logOut() {
    await Auth.signOut();
    userHasAuthenticated(false);
    _setUser({});
    navigate("/");
  }

  return (
    !isAuthenticating && (
      <Navbar collapseOnSelect expand="lg" bg="lg" variant="light">
        <Container>
          <Navbar.Brand href="/">PingPongr</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          {!isAuthenticated ? (
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="m-auto">
                <Nav.Link href="/home">Home</Nav.Link>
                {/* <Nav.Link href="/members">Members</Nav.Link> */}
                <Nav.Link href="/Events">Events</Nav.Link>
                {/* <Nav.Link href="/contact">Contact</Nav.Link> */}
              </Nav>
              <Nav>
                <Nav.Link href="/signup">Sign Up</Nav.Link>
                <Nav.Link eventKey={2} href="/login">
                  Login
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          ) : (
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="m-auto">
                {/* <Nav.Link href="/home">Home</Nav.Link> */}
                {/* <Nav.Link href="/members">Members</Nav.Link> */}
                <Nav.Link href="/clubs">Clubs</Nav.Link>
                <Nav.Link href="/Events">Events</Nav.Link>
                {/* <Nav.Link href="/notes">Notes</Nav.Link> */}
                {/* <Nav.Link href="/contact">Contact</Nav.Link> */}
              </Nav>
              <Nav>
                <NavDropdown title={user.email} id="collasible-nav-dropdown">
                  <NavDropdown.Item className="dropdown-font" href="/settings">
                    Account Settings
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item className="dropdown-font" onClick={logOut}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          )}
        </Container>
      </Navbar>
    )
  );
}

export default MyNavbar;
