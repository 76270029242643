import React, { useState } from "react";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { onError } from "../../lib/errorLib";
import config from "../../config";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import BillingForm from "../BillingForm/BillingForm";
import "./Settings.css";
export default function Settings() {
  const nav = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const stripePromise = loadStripe(config.STRIPE_KEY);
  function billUser(details) {
    return API.post("notes", "/billing", {
      body: details,
    });
  }
  async function handleFormSubmit(storage, { token, error }) {
    if (error) {
      onError(error);
      return;
    }

    setIsLoading(true);

    try {
      await billUser({
        storage,
        source: token.id,
      });

      alert("Your card has been charged successfully!");
      nav("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  return (
    <div className="Settings">
      <Elements
        stripe={stripePromise}
        fonts={[
          {
            cssSrc:
              "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800",
          },
        ]}
      >
        <BillingForm isLoading={isLoading} onSubmit={handleFormSubmit} />
      </Elements>
    </div>
  );
}

// return (
//   <div className="Settings">
//     <LinkContainer to="/settings/email">
//       <LoaderButton block bsSize="large">
//         Change Email
//       </LoaderButton>
//     </LinkContainer>
//     <LinkContainer to="/settings/password">
//       <LoaderButton block bsSize="large">
//         Change Password
//       </LoaderButton>
//     </LinkContainer>
//     <hr />
//     <Elements
//       stripe={stripePromise}
//       fonts={[
//         {
//           cssSrc:
//             "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800",
//         },
//       ]}
//     >
//       <BillingForm isLoading={isLoading} onSubmit={handleFormSubmit} />
//     </Elements>
//   </div>
// );
