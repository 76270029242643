import { recoilPersist } from "recoil-persist";
import { atom } from "recoil";

const { persistAtom } = recoilPersist({
  key: "recoil-persist",
  storage: localStorage,
});

export const userState = atom({
  key: "user",
  default: {
    userId: null, // cognito identity id
    userName: null, // cognito username
    email: null,
    firstName: null,
    lastName: null,
    isMember: null, // list of club ids
    isAdmin: null, // list of club ids
    elo: null, // list of objects with club id and elo
    matches: null, // list of objects with club id and matches
    wins: null, // list of objects with club id and wins
    losses: null, // list of objects with club id and losses
    events: null, // list of objects with club id and events
    createdAt: null, // list of objects with club id and events
    updatedAt: null, // list of objects with club id and events
  },
  effects_UNSTABLE: [persistAtom],
});
