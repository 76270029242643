import React from "react";
import styled from 'styled-components';



const NotFoundPadding = styled.div`
    padding-top: 200px;
    padding-bottom: 200px;
    text-align: center;
`;


export default function NotFound() {
  return (
   <NotFoundPadding>
     <h3>Sorry, page not found!</h3>
   </NotFoundPadding>
     
  );
}